<template>
  <div >
  <notification :message="message"></notification>
    <top-header-template>
        <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Terms and Conditions have not been accepted
                </h2>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md mb-1 mx-6">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form action="#" method="POST" @submit.prevent="logout">
                    <p>A client admin needs to accept the terms and conditions before you can continue, Please ask a client admin to sign in and accept the terms and conditions.</p>
                    <div class="mt-6">
                      <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:ring-primary active:bg-primary-700 transition duration-150 ease-in-out">Sign Out</button>
                      </span>
                    </div>
                </form>
              </div>
            </div>
        </div>
    </top-header-template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Notification from "../components/Notification";
import Siren from "super-siren";
import TopHeaderTemplate from "../templates/TopHeaderTemplate";

export default {
  components: {TopHeaderTemplate, Notification },

  computed: {
    ...mapGetters({
         accessToken: "getAccessToken",
        message: "getMessage",
    })
  },
  mounted() {
    if (this.accessToken === null) {
      return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
        this.$router.push({ path: "/login" });
      });
    }
  },

  methods: {
      logout() {
          this.$store.dispatch("logout").then(() => this.$router.push("/login"));
      },
      messages(response) {
          return response.body.entities
              .filter(function (entity) {
                  return entity.rels.contains("status-messages");
              })
              .first();
      },
  }
};
</script>
